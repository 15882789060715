





































import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class HomeShop extends Vue {
  public show = false;
  public minDate=new Date(2010, 0, 0);
  public maxDate=new Date();
  public currentDate= new Date();
  public user_id = '';
  public goodsList = [];
  
  public year = new Date().getFullYear()
  public month = new Date().getMonth()+1

  showTime() {
    this.show = !this.show;
  }

  confirm(e){
    this.year = e.getFullYear()
    this.month = e.getMonth()+1
    this.getInfo()
    this.show = false
  }

  goList(){
    this.$router.push('/home/list?id='+this.user_id)
  }

  getInfo(){
    let _this = this
    this.$api.request({
      url: "user/users/repertory",
      data: { user_id: this.user_id,date:`${this.year}-${this.month}-01` },
      success(res) {
        _this.goodsList = res.data
      }
    })
  }

  init(){
    this.user_id = String(this.$route.query.id || "");
    this.getInfo()
  }

  formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    }
}
